import React from 'react';
import { Image } from 'react-bootstrap';
import { Contact } from './Contact';
import './App.css'; // Import custom CSS for styling
import { Helmet } from 'react-helmet';

export const AboutCompany = () => {
  const partners = [
    {
      name: "Taleemabad",
      logo: require('./tbd.jpg'),
    },
    {
      name: "xad technologies",
      logo: require('./xad.jpg'),
    },
    {
      name: "translation empire",
      logo: require('./tranemp.jpg'),
    },
    {
      name: "Up Work.com",
      logo: require('./upwork.png'),
    },
    {
      name: "Fiverr.com",
      logo: require('./fiverr.jpg'),
    },
    {
      name: "royal national college kharian",
      logo: require('./rnc.png'),
    },
    {
      name: "royal national college kharian",
      logo: require('./uom.png'),
    },
    {
      name: "royal national college kharian",
      logo: require('./bgoc.jpeg'),
    },
  ];

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const partnerChunks = chunkArray(partners, 4);

  return (
    <div className='app'>
      <Helmet>
        <title>About us - Data Fitt</title>
      </Helmet>
      <div className='container-fluid sub-herosection'>
        <h1 className='display-4'>About Us</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item"><a href="/AboutCompany">About Us</a></li>
            <li className="breadcrumb-item active" aria-current="page" style={{color:'#3a5a40', textDecoration:'underline'}}>About Company</li>
          </ol>
        </nav>
      </div>
      <div className="container my-5 ">
        <h1 className="mb-3 text-center">
          <span>Our Mission</span>
        </h1>
        <div className="bg-light p-4 rounded text-left">
          <p className="mb-0 text-left">
          Al Falah Maid Services is a leading provider of professional staffing and housekeeping solutions, offering a wide range of services to meet diverse household and business needs. Specializing in Maid Services, the company supplies trained and reliable personnel, including maids, babysitters, cooks, chefs, helpers, and caregivers for patient care. Whether clients need assistance with daily chores, expert childcare, gourmet meal preparation by skilled chefs, or compassionate support for elderly or ailing family members, Al Falah Maid Services ensures trustworthy and highly skilled staff are available. With a commitment to quality service, rigorous staff selection, and customer satisfaction, they are a dependable choice for professional support across various roles.          </p>
        </div>
      </div>
      <Contact />
    </div>
  );
};

