import React from 'react';
import {Contact}from './Contact';
import Image from '../src/bg.png';
import { Helmet } from 'react-helmet';

export const  DataPipe=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Maid Services - Al Falah Maid Services</title>
    </Helmet>
      <div className='container-fluid sub-herosection'>
        <h1 className='display-4'>Maid Services</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item"><a href="/ServicesPage">Services</a></li>
            <li className="breadcrumb-item active" aria-current="page" style={{color:'#3a5a40', textDecoration:'underline'}}>Maid Services</li>
          </ol>
        </nav>
      </div>
      <div className="container-fluid service-section ">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="service-card border">
              <h2 className="services">Daily Maid Services</h2>
              <img src={Image} alt="Daily Maid Services" className="service-img img-fluid py-4"/>
              <p>
              You can hire a housemaid in Pakistan for household chores, with salaries ranging from Rs. 1,000 to 4,000 Per Day.
              </p>
              <a href='GetinTouch'><button className="btn btn-primary w-100">Book Now</button></a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="service-card border">
              <h2 className="services">Monthly Maid Services</h2>
              <img src={Image} alt="Monthly Maid Services" className="service-img img-fluid py-4"/>
              <p>
              You can hire a housemaid in Pakistan for household chores, with salaries ranging from Rs. 25,000 to 30,000 monthly.
              </p>
              <a href='GetinTouch'><button className="btn btn-primary w-100">Book Now</button></a>
            </div>
          </div>
        </div>
      </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .DataPipe {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    </div>
  );
};
