import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faChartLine, faChartPie, faRobot, faCode, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

export const Services = () => {
    return (
        <div className="container py-5 justify-content-center" style={{  backgroundColor: '#f9f9f9', borderRadius: '10px' }}>
           <div className='text-center'>
           <h1 className="section-title">Our Services</h1>
            <p className="section-subtitle">Your Home Friend</p>
            <p style={{marginTop:'-10px', width:'80%', margin:'auto'}}>
            We provide the best maid services across Pakistan. Our maid services are professional, reliable, and available nationwide. Choose our maid services for top-quality domestic help you can trust.
            </p>
           </div>
            <div className="row">
                {servicesData.map((service, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <div className="service-card d-flex flex-column justify-content-between p-4" style={{ border: '1px solid #e0e0e0', borderRadius: '8px', height: '90%', transition: 'transform 0.2s' }}>
                            <div>
                                <FontAwesomeIcon icon={service.icon} size="2x" className="mb-3" style={{ color: '#00406c' }} />
                                <h5>{service.title}</h5>
                                <p>{service.description}</p>
                            </div>
                            <a href={service.link} className="cus-btn w-100 mt-auto">View Our Work</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const servicesData = [
    {
        title: 'Maid Services',
        description: 'Professional and reliable maid services for a spotless home. Book your appointment now!',
        link: 'DataPipe',
        icon: faDatabase
    },
    {
        title: 'Babysitter Services',
        description: 'Trusted and caring babysitter services ensuring your child is in safe and nurturing hands. Schedule your babysitting session today!',
        link: 'DsbCreationOpt',
        icon: faChartLine
    },
    {
        title: 'Patient care Services',
        description: 'Professional and compassionate patient care services ensuring your loved ones receive the best possible care and support in the comfort of their own home.',
        link: 'AnalyticsInsights',
        icon: faChartPie
    },
    {
        title: 'Cook / Cheif Services',
        description: 'Providing professional and delicious meal preparation services for your home or event, tailored to your specific needs and preferences.',
        link: 'CustBehavior',
        icon: faChartLine
    },
    {
        title: 'Helper Services',
        description: 'Offering reliable and efficient helper services to assist with household tasks, ensuring a smooth and stress-free experience.',
        link: 'ConsulTran',
        icon: faRobot
    },
    {
        title: 'Maid Services',
        description: 'Expert maid services for your home or office, providing a sparkling clean and healthy environment.',
        link: 'ConsulTran',
        icon: faRobot
    },
];
