import React from 'react';
import {Services}from './Services';
import {Contact}from './Contact';
import { Helmet } from 'react-helmet';


import './App.css';  // Import custom CSS for styling';

export const  ServicesPage=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Our All Serverices - Al Falah Maid Services</title>
    </Helmet>
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Our All Serverices</h1>
        </div>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .services-page {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Services />
    <Contact/>
    </div>
    
  );
};
