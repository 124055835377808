import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import video from '../src/bgvid.mp4';

export const HeroSection = () => {
  return (
    <div className="hero-section d-flex align-items-center justify-content-center" style={{position:'relative',}}>
      <video 
        src={video} 
        className="video-background" 
        autoPlay 
        loop 
        muted 
      />
      <div className="overlay" ></div>
      <div className="content d-flex flex-column justify-content-center align-items-center">
        <h1 style={{color:'#fff'}}>Al Falah Maid Services</h1>
        <p>We Know Your Pain</p>
        <div className="button-container">
            <button className="cus-button filled-btn"><a href='AboutCompany'>Discover More</a></button>
            <button className="cus-button outline-btn"><a href='GetinTouch'>Get In Touch</a></button>
        </div>
      </div>
    </div>
  );
}