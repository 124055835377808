import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../src/about-img.jpg';
import './App.css'; 
/**
 * AboutSection component renders the "About Us" section of the webpage.
 * It provides an overview of DataFitt's services and expertise in data analytics,
 * predictive modeling, and AI solutions. The section includes a descriptive text
 * and buttons for navigation to learn more about the company or get in touch.
 * Additionally, it displays an image to visually represent the company's mission.
 */
export const AboutSection = () => {
  return (
    <section className="container my-5" style={{backgroundColor:'#f9f9f9'}}>
      <div className="row align-items-center">
        <article className="col-md-6" style={{padding:'30px'}}>
          <h3 className="card-title mb-3" style={{color:'#00406c', fontWeight:'bold'}}>Making Your Home Sparkle with Our Expertise</h3>
          <p className="card-text">
          At Al Falah Maid Services, we believe in providing the 
          best and most trusted maid services all over Pakistan.
          Imagine having a team of experienced and trained maids
          taking care of your household chores, with the guarantee
          of quality service and customer satisfaction. Our team
          of professionals is dedicated to providing you with the
          best experience possible, so you can focus on what matters
          most to you. Plus, with our wide range of services, from
          daily to live-in maids, we have got you covered.
          </p>
          <div className="button-container">
            <Link to="/AboutCompany">
              <button className="cus-button filled-btn" style={{width:'100%', borderRadius:'10px'}}>Discover More</button>
            </Link>
          </div>
        </article>
        <div className="col-md-6">
          <img
            src={image}
            alt="Team collaborating on data analytics"
            className="img-fluid rounded"
          />
        </div>
      </div>
    </section>
  );
}