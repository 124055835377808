import React from 'react';
import {Contact}from './Contact';
import { Helmet } from 'react-helmet';


import './App.css';  // Import custom CSS for styling';

export const  GetinTouch=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Get in Touch - Data Fitt</title>
    </Helmet>
      <div className='container-fluid sub-herosection'>
        <h1 className='display-4'>Get in Touch</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item"><a href="/GetinTouch">Contact</a></li>
            <li className="breadcrumb-item active" aria-current="page" style={{color:'#3a5a40', textDecoration:'underline'}}>Get in Touch</li>
          </ol>
        </nav>
      </div>
    <style jsx global>{`
        .contacts {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    </div>
    
  );
};
